
import { ComponentPropsOptions, computed, defineComponent, isVue2, onMounted, PropType, Ref, ref } from 'vue-demi'
import { isRefObject } from '@svoi-ui/shared/utils/guards'
import { uuid } from '@svoi-ui/shared/utils/helpers'
import { SvoiHint } from '@svoi-ui/components/hint'
import { SvoiIcon } from '@svoi-ui/components/icon'

const modelProp: ComponentPropsOptions = isVue2
  ? {
      value: {
        type: String,
        default: ''
      }
    }
  : {
      modelValue: {
        type: String,
        default: ''
      }
    }

export default defineComponent({
  name: 'SvoiTextarea',
  components: { SvoiIcon, SvoiHint },
  props: {
    ...modelProp,
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    rows: {
      type: Number,
      default: null
    },
    showCount: {
      type: Boolean,
      default: true
    },
    error: {
      type: String as PropType<string | Ref<string>>,
      default: null
    },
    example: {
      type: String,
      default: null
    },
    maxLength: {
      type: Number,
      default: null
    },
    resizeable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: Number,
      default: 0
    }
  },
  emits: ['input', 'focus', 'blur', 'update:modelValue'],
  setup(props, { emit }) {
    const focus = ref(false)
    const id = ref('')
    onMounted(() => (id.value = uuid()))

    const computedValue = computed({
      get() {
        // @ts-expect-error
        return isVue2 ? String(props.value) : String(props.modelValue)
      },
      set(value) {
        emit(isVue2 ? 'input' : 'update:modelValue', value)
      }
    })

    const characterCount = computed(() => {
      return (computedValue.value as string).toString().length
    })

    const counterDisplay = computed(() => {
      return props.showCount && !props.error
    })

    const classes = computed(() => {
      return {
        '-focus': isRefObject(focus) && focus.value,
        '-filled': isRefObject<string>(computedValue) && (computedValue.value as string).length > 0,
        '-resizeable': props.resizeable,
        '-disabled': props.disabled,
        '-error': props.error
      }
    })

    const onActivate = () => {
      if (!props.disabled) {
        focus.value = true
        emit('focus')
      }
    }

    const onBlur = () => {
      focus.value = false
      emit('blur')
    }

    return {
      id,
      computedValue,
      characterCount,
      counterDisplay,
      classes,
      onActivate,
      onBlur
    }
  }
})


import {
  computed,
  defineComponent,
  PropType,
  reactive,
  Ref,
  ref,
  useContext,
  useRoute,
  watch
} from '@nuxtjs/composition-api'
import { SvoiButton } from '@svoi-ui/components/button'
import { SvoiInput, SvoiMaskedInput } from '@svoi-ui/components/input'
import { SvoiTextarea } from '@svoi-ui/components/textarea'
import { useVuelidate } from '@vuelidate/core'
import { LandingForm } from '~/services/Landing'
import { UserModel } from '~/model/User'
import type { LandingFeedbackBlockModel } from '~/model/Landings'

export default defineComponent({
  name: 'LandingFeedbackForm',
  components: { SvoiButton, SvoiInput, SvoiMaskedInput, SvoiTextarea },
  props: {
    blockContent: {
      type: Object as PropType<LandingFeedbackBlockModel>,
      default: () => null
    }
  },
  setup(props) {
    const context = useContext()
    const route = useRoute()
    const routeSlug = route.value.params.slug
    const user = computed(() => {
      const authorizedUser = context.$tstore.auth.authorizedUser

      return authorizedUser || new UserModel(null)
    })
    const entity = ref(new LandingForm(props.blockContent.fields, user.value)) as Ref<LandingForm>
    const form = useVuelidate(LandingForm.rules(props.blockContent.fields), reactive(entity.value.values))

    const isButtonDisabled = computed(() => entity.value._invalid)

    const sendingForm = computed(() => {
      return entity.value.fields.map(item => {
        return {
          ...item,
          value: entity.value.values[item._index]
        }
      })
    })

    watch(
      () => form.value.$invalid,
      () => (entity.value._invalid = form.value.$invalid)
    )

    const submitForm = () => {
      context.$services.landingFormService
        .sendForm(sendingForm.value, routeSlug)
        .then(() => {
          context.$tstore.notification.setNotification({
            text: 'Поздравляем, заявка отправлена',
            type: 'success'
          })
        })
        .finally(() => {
          entity.value.clear()
          form.value.$reset()
        })
    }

    return {
      entity,
      form,
      isButtonDisabled,
      submitForm
    }
  }
})
